<script lang="ts" setup>
const route = useRoute()

const appUrl = import.meta.env.MODE === 'production' ? 'https://app.jpool.one' : 'https://dev.jpool.one'
const originUrl = `${appUrl}${route.fullPath}`

useHead({
  title: import.meta.env.VITE_APP_TITLE,
  titleTemplate: title => `${title} | JPool`,
  meta: [
    { name: 'description', content: import.meta.env.VITE_APP_DESCRIPTION },
    { name: 'keywords', content: import.meta.env.VITE_APP_KEYWORDS },
    { property: 'og:title', content: 'JPool' },
    { property: 'og:description', content: import.meta.env.VITE_APP_DESCRIPTION },
    { property: 'og:type', content: 'website' },
    { property: 'og:image', content: `${appUrl}/img/logo.svg` },
    { property: 'og:url', content: originUrl },
    { name: 'twitter:title', content: import.meta.env.VITE_APP_TITLE },
    { name: 'twitter:creator', content: '@JPoolSolana' },
  ],
})

const auth = useAuthStore()
const isClient = !import.meta.env.SSR
const isPasswordProtected = computed(() => auth.isEnabled && !auth.isAuthenticated && isClient)
const { initStorageVersion } = useStorageVersion()

onBeforeMount(() => {
  initHcApi()
  initWallet()
  initStorageVersion()
})
</script>

<template>
  <password-protect v-if="isPasswordProtected" />
  <router-view v-else />
</template>
